<template lang="pug">
  v-row
    v-col.pa-0.pa-sm-3(cols="12")
      v-tabs(v-model="vehiculoTab" icons-and-text show-arrows)
        v-tab(v-for="n in vehiculos", :key="n", :disabled="loading_modelos")
          | Vehículo {{ n }}
          v-icon {{ getIcon(n) }}
          v-badge.pl-6(v-if="tiene_error(n)" color="error" dot)
      v-tabs-items(v-model="vehiculoTab")
        v-tab-item(v-for="n in vehiculos", :key="n")
          v-form(:ref="'form_vehiculo_' + (n-1)")
            v-card.d-flex(outlined, :disabled="loading_modelos")
              div.d-flex.flex-column
                v-item-group(mandatory active-class="bordeactivo" v-model="vehiculosArr[n-1].tipo")
                  v-item(v-for="(item, i) in opciones", :key="i" v-slot="{ active, toggle }", :value="item.valor")
                    v-card.ma-2.bordesecondary(outlined @click="click(toggle, n-1)")
                      div.d-flex.align-center
                        v-icon.mr-3(color="secondary" large) {{ item.icon }}
                        div.text-body-1 {{ item.titulo }}
              v-card-text(v-if="vehiculosArr[n-1].tipo === 'menor'")
                v-row
                  v-col(cols="12" sm="6")
                    v-autocomplete(label="Marca" v-model="marca" outlined hide-no-data, :items="marcasArr" required, :rules="rules(true)")
                  v-col(cols="12" sm="6")
                    v-autocomplete(label="Modelo" v-model="modelo" outlined hide-no-data, :items="modelosArr", :loading="loading_modelos" required, :rules="rules(true)", :disabled="!marca || loading_modelos")
                v-row(align="center")
                  v-col(cols="12" sm="6")
                    v-select(label="Carga adicional" v-model="vehiculosArr[n-1].extra", outlined hide-no-data :items="adicionales" required, :rules="rules(true)" @change="onChangeExtra($event, n)")
                  v-col(v-if="vehiculosArr[n-1].extra === 1" cols="12" sm="6")
                    v-text-field(v-model="vehiculosArr[n-1].largo_carro" outlined label="Largo carro de arrastre" suffix="m" placeholder="1.5" required, :rules="rules(true, 'positivo')" type="number" min="0")
                v-row(align="center" no-gutters)
                  v-col.d-flex(cols="12")
                    v-icon.mr-2(color="warning") mdi-alert
                    div.text-body-1.text-decoration-underline.error--text Esta información debe ser fidedigna, o podría negarse su embarque
              v-card-text(v-else-if="vehiculosArr[n-1].tipo === 'bus'")
                v-row(align="center" justify="center")
                  v-col(cols="auto")
                    v-radio-group(mandatory hide-details v-model="vehiculosArr[n-1].subtipo")
                      v-radio(color="secondary" label="Motorhome" value="motorhome")
                      v-radio(color="secondary" label="Minibus" value="minibus")
                      v-radio(color="secondary" label="Bus" value="bus")
                  v-col
                    v-icon(size="80" color="primary") {{ getSubIcon(n) }}
                v-row
                  v-col(cols="12" lg="4" sm="6")
                    v-text-field(v-model="vehiculosArr[n-1].largo" outlined hide-details label="Largo" placeholder="6.5" suffix="m" required :rules="rules(true, 'positivo')" type="number" min="0")
                  v-col(cols="12" lg="4" sm="6")
                    v-text-field(v-model="vehiculosArr[n-1].ancho" outlined hide-details label="Ancho" placeholder="2" suffix="m" required :rules="rules(true, 'positivo')" type="number" min="0")
                  v-col(cols="12" lg="4" sm="6")
                    v-text-field(v-model="vehiculosArr[n-1].alto" outlined hide-details label="Alto" placeholder="2.5" suffix="m" required :rules="rules(true, 'positivo')" type="number" min="0")
                // v-row(align="center")
                  div.ma-3.d-flex.align-center
                    v-checkbox.mx-2(v-model="vehiculosArr[n-1].ca" dense hide-details label="Carro de arrastre")
                    v-checkbox.mx-2(v-model="vehiculosArr[n-1].pe" dense hide-details label="Porta equipaje")
                    v-checkbox.mx-2(v-model="vehiculosArr[n-1].pm" dense hide-details label="Porta bicicleta")
              v-card-text(v-else-if="vehiculosArr[n-1].tipo === 'moto'")
                // v-row(align="center")
                  div.ma-3.d-flex.align-center
                    v-checkbox.mx-2(v-model="vehiculosArr[n-1].ca" dense hide-details label="Carro de arrastre")
                    v-checkbox.mx-2(v-model="vehiculosArr[n-1].pe" dense hide-details label="Porta equipaje")
                    v-checkbox.mx-2(v-model="vehiculosArr[n-1].pm" dense hide-details label="Porta bicicleta")
              v-card-text(v-else-if="vehiculosArr[n-1].tipo === 'bici'")
                // v-row(align="center")
                  div.ma-3.d-flex.align-center
                    v-checkbox.mx-2(v-model="vehiculosArr[n-1].ca" dense hide-details label="Carro de arrastre")
                    v-checkbox.mx-2(v-model="vehiculosArr[n-1].pe" dense hide-details label="Porta equipaje")
                    v-checkbox.mx-2(v-model="vehiculosArr[n-1].pm" dense hide-details label="Porta bicicleta")
</template>

<script>

export default {
  name: 'VehiculosTab',

  props: {
    value: {
      type: [String, Number],
      default: 0
    },
    label: {
      type: String,
      default: ''
    },
    max: {
      type: [String, Number],
      default: 99
    },
    min: {
      type: [String, Number],
      default: 0
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    vehiculoTab: null,
    marca: null,
    modelo: null,
    counter: 0,
    loading_modelos: false,
    opciones: [
      {
        icon: 'mdi-car',
        titulo: 'Vehículo menor',
        valor: 'menor'
      },
      /* {
        icon: 'mdi-rv-truck',
        titulo: 'Motorhome / Bus',
        valor: 'bus'
      }, */
      {
        icon: 'mdi-motorbike',
        titulo: 'Motocicleta',
        valor: 'moto'
      },
      {
        icon: 'mdi-bicycle',
        titulo: 'Bicicleta',
        valor: 'bici'
      },
    ],
    adicionales: [
      {
        value: 0,
        text: 'Sin adicional'
      },
      {
        value: 1,
        text: 'Carro de arrastre',
        t: 'ca'
      },
      {
        value: 2,
        text: 'Altura adicional',
        t: 'pe'
      },
      {
        value: 3,
        text: 'Porta bicicleta',
        t: 'pm'
      }
    ]
  }),

  watch: {
    vehiculoTab (val) {
      if (val === null) {
        return
      }
      this.marca = this.vehiculosArr[val].marca
      this.modelo = this.vehiculosArr[val].modelo
    },

    async marca (val) {
      if (!val || this.vehiculoTab === null) {
        return
      }

      const data = {
        n: this.vehiculoTab,
        marca: val
      }
      this.$store.commit('SET_MARCA', data)
      this.loading_modelos = true
      await this.$store.dispatch('getModelos', val)
      this.loading_modelos = false
      this.counter++
    },

    modelo (val) {
      if (this.vehiculoTab === null) {
        return
      }
      this.vehiculosArr[this.vehiculoTab].modelo = val
    }
  },

  computed: {
    vehiculosArr () {
      return this.$store.state.vehiculosArr
    },

    vehiculos () {
      const vehiculos = this.$store.state.vehiculosArr.length
      if (vehiculos === 0) {
        this.resetearVehiculos()
      }
      return vehiculos
    },

    marcasArr () {
      if (this.$store.state.marcas) {
        return this.$store.state.marcas
      } else {
        return []
      }
    },

    modelosArr () {
      if (this.counter >= 0) {
        const modelos = this.$store.state.marcasObj[this.marca]
        return modelos || []
      }
      return []
    }
  },

  methods: {
    click (toggle, v) {
      this.$forceUpdate()
      const tipo = this.$store.state.vehiculosArr[v].tipo
      const tag = this.$store.state.vehiculosArr[v].tag
      this.$store.state.vehiculosArr[v] = {
        tipo,
        tag,
        subtipo: 'motorhome',
        marca: null,
        modelo: null,
        pe: false,
        pm: false,
        ca: false,
        ancho: undefined,
        largo: undefined,
        alto: undefined,
        largo_carro: undefined,
        extra: undefined
      }
      toggle()
    },

    onChangeExtra (event, v) {
      const extra = {
        ca: false,
        pe: false,
        pm: false
      }
      switch (event) {
        case 1: extra.ca = true; break
        case 2: extra.pe = true; break
        case 3: extra.pm = true; break
      }
      this.vehiculosArr[v-1] = {
        ...this.vehiculosArr[v-1],
        ...extra
      }
      this.$forceUpdate()
    },

    getIcon (n) {
      switch(this.vehiculosArr[n-1].tipo) {
        case 'menor':
          return 'mdi-car'
        case 'bus':
          return this.getSubIcon(n)
        case 'moto':
          return 'mdi-motorbike'
        case 'bici':
          return 'mdi-bicycle'
      }
      return 'mdi-help'
    },

    getSubIcon (n) {
      switch(this.vehiculosArr[n-1].subtipo) {
        case 'motorhome':
          return 'mdi-rv-truck'
        case 'minibus':
          return 'mdi-bus-school'
        case 'bus':
          return 'mdi-bus-side'
      }
      return 'mdi-help'
    },

    resetearVehiculos () {
      this.vehiculoTab = null
      this.marca = null
      this.modelo = null
    },

    validar () {
      let es_valido = true
      for (let v = 0; v < this.vehiculos; v++) {
        let valido
        if (this.vehiculoTab === v) {
          const form = this.$refs['form_vehiculo_' + v]
          if (form) {
            valido = form[0].validate()
          } else {
            valido = true
          }
        } else {
          valido = this.validacion_manual(v)
        }
        es_valido = es_valido && valido
        this.vehiculosArr[v].error = !valido
      }
      this.$forceUpdate()
      return es_valido
    },

    validacion_manual (v) {
      const vehiculo = this.vehiculosArr[v]

      switch (vehiculo.tipo) {
        case 'menor':
          if (!vehiculo.marca) {
            return false
          }
          if (!vehiculo.modelo) {
            return false
          }
          if (vehiculo.extra === undefined) {
            return false
          }
          if (vehiculo.extra === 1 && !vehiculo.largo_carro) {
            return false
          }
          return true
        case 'bus':
          if (!vehiculo.largo || !vehiculo.ancho || !vehiculo.alto) {
            return false
          }
          return true
        case 'moto':
        case 'bici':
          return true
      }
    },

    tiene_error (n) {
      return this.vehiculosArr[n-1].error
    }
  }
}

</script>

<style type="text/css">
.bordesecondary {
  border-color: #FF9900 !important;
  padding: 10px !important;
}

.bordeactivo {
  border-color: #003399 !important;
  border-width: medium !important;
  padding: 8px !important;
}
</style>
