<template lang="pug">
v-card
  v-card-text
    v-form(ref="form" v-model="form")
      v-row.white(justify="center" fill-height)
        v-col.text-center(cols="12")
          div.text-md-h4.text-h6.primary--text RECUPERAR TICKETS
      v-row.my-6(justify="center" fill-height)
        v-col(cols="12" sm="6" lg="4")
          v-select(label="Identificación" v-model="doc" :items="ids" prepend-icon="mdi-account-box" color="primary" :disabled="loading")
        v-col(cols="12" sm="6" lg="4")
          v-text-field(v-model="rut" :label="doc" prepend-icon="mdi-account-box" color="primary" :disabled="loading" required :rules="rules(true, doc)" clearable @click:clear="limpiar" @blur="onBlur" @keyup.enter="buscar" validate-on-blur)
    v-expand-transition
      v-row.mb-4(v-if="respuesta" justify="center" fill-height)
        v-col(cols="12")
          div.text-h5.text-center(v-bind:class="color + '--text'") {{ msg }}
        v-col(v-if="resultado" cols="12")
          v-simple-table
            template(v-slot:default)
              thead
                tr
                  th.text-center Reserva
                  th.text-center Fecha Viaje
                  th.text-center Tickets
              tbody
                tr(v-for="(item, index) in resultado" :key="index")
                  td.text-center {{ item.nReserva }}
                  td.text-center {{ $moment.utc(item.fechaViaje).tz('America/Santiago').format('DD/MM/YYYY - HH:mm:ss a') }}
                  td.text-center
                    div(v-for="(documento, i) in item.documentos" :key="i")
                      a(v-if="documento" :href="getPdfLink(documento.ticket)" download :key="i") Descargar Tickets

  v-card-actions
    v-btn(color="primary" block :loading="loading" @click="buscar") Buscar
</template>

<script>

import { format } from 'rut.js'

export default {
  name: 'App',
  data: () => ({
    rut: '',
    doc: 'RUT',
    ids: ['RUT', 'Pasaporte'],
    respuesta: false,
    resultado: null,
    msg: '',
    color: 'success',
    loading: false,
    form: false,
  }),

  mounted( ) {
  },

  methods: {
    async buscar () {
      if (this.$refs.form.validate()) {
        this.loading = true
        this.respuesta = false
        this.resultado = null
        const rut = this.doc === 'RUT' ? format(this.rut, {dots: false}) : this.rut.toUpperCase()
        const data = {
          rut
        }
        const res = await this.$store.dispatch('recuperar', data)
          .catch(() => {
            return {
              err: true
            }
          })

        this.respuesta = true
        this.loading = false

        if (!res || res.length === 0 || Object.keys(res).length === 0) {
          this.color = 'error'
          this.msg = 'No se encontraron tickets asociados al ' + this.doc
          return
        }

        if (res.err) {
          this.color = 'error'
          this.msg = 'Error al obtener los tickets. Comuníquese con la Mesa de Ayuda'
          return
        }

        this.color = 'success'
        this.msg = 'Se encontraron tickets asociados al ' + this.doc
        this.resultado = res
        this.resultado.sort((a, b) => b.nReserva.localeCompare(a.nReserva))
      }
    },

    base64ToBlob (base64, contentType = "application/pdf") {
      const buffer = Buffer.from(base64, 'base64')
      return new Blob([buffer], { type: contentType })
    },

    getPdfLink (base64) {
      const blob = this.base64ToBlob(base64)
      const pdfUrl = URL.createObjectURL(blob)
      return pdfUrl
    },

    limpiar () {
      this.respuesta = false
      this.resultado = null
      this.form = false
    },

    onBlur () {
      if (this.doc === 'RUT') {
        if (!this.rut?.length) {
          return
        }
        this.rut = format(this.rut, {dots: false})
      } else {
        this.rut = this.rut?.toUpperCase()
      }
    }
  }
}
</script>

<style type="text/css">

</style>